<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  font-family: 'Garamond', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

nav {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.nav-link {
  text-decoration: none;
  color: #2c3e50;
  margin: 0 10px;
}

.nav-link:hover, .nav-link:focus {
  color: #1abc9c;
}
</style>