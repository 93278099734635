import { createRouter, createWebHistory } from 'vue-router';
import LawphoriaView from '@/views/LawphoriaView.vue';
import LawyerConnectView from '@/views/LawyerConnectView.vue';
import LawyerLoginView from '@/views/LawyerLoginView.vue';

const routes = [
    {
        path: '/',
        name: 'Lawphoria',
        component: LawphoriaView,
    },
    {
        path: '/lawyer-connect',
        name: 'LawyerConnect',
        component: LawyerConnectView,
    },
    {
        path: '/lawyer-login',
        name: 'LawyerLogin',
        component: LawyerLoginView,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;