<template>
  <div class="lawphoria" :class="{ 'light-mode': isLightMode }">
    <header class="header">
      <div class="logo">
        <img :src="logoSrc" alt="Lawphoria Logo" />
      </div>
      <div class="mode-toggle" @click="toggleMode">
        <i class="fas" :class="{ 'fa-moon': !isLightMode, 'fa-sun': isLightMode }"></i>
      </div>
    </header>

    <main class="main-content">
      <h1 class="title">Your AI-powered Indian Law Assistant</h1>

      <div class="chatbot">
        <div class="chat-container" ref="chatContainer">
          <div v-for="message in messages" :key="message.id" :class="['message', message.type]">
            <div class="message-content">
              <h3 v-if="message.type === 'ai'">Lawphoria</h3>
              <h3 v-else>You</h3>
              <p>{{ message.text }}</p>
            </div>
          </div>
          <div v-if="isLoading" class="message ai">
            <div class="message-content">
              <h3>Lawphoria</h3>
              <div class="loader">
                <div></div><div></div><div></div>
              </div>
            </div>
          </div>
        </div>

        <form @submit.prevent="sendMessage" class="input-container">
          <label for="file-upload" class="upload-btn" title="Upload an image">
            <i class="fas fa-paperclip"></i>
          </label>
          <input ref="fileInput" id="file-upload" type="file" @change="handleFileUpload" accept="image/*" style="display: none;" />
          <input type="text" v-model="userInput" placeholder="Ask about Indian law..." />
          <button type="submit" class="send-btn" :disabled="!userInput.trim() || isLoading">
            <i class="fas fa-paper-plane"></i>
          </button>
        </form>
      </div>
    </main>

    <footer>
      <p>&copy; {{ currentYear }} Neuralway Technologies Private Limited. All rights reserved.</p>
    </footer>

    <transition name="fade">
      <div v-if="showUploadFinishedPopup" class="upload-popup">
        <h3>Image Uploaded</h3>
        <p>Do you want to send this image to Lawphoria for analysis?</p>
        <div class="popup-actions">
          <button @click="sendImageToLawphoria" class="btn-primary">Analyze Image</button>
          <button @click="closeUploadPopup" class="btn-secondary">Cancel</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "LawphoriaView",
  data() {
    return {
      userInput: "",
      messages: [],
      openaiApiKey: process.env.VUE_APP_OPENAI_API_KEY,
      showUploadFinishedPopup: false,
      uploadedImageBase64: null,
      isLoading: false,
      isLightMode: false,
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    logoSrc() {
      return this.isLightMode ? require('@/assets/Lawphoria.png') : require('@/assets/LawphoriaInverted.png');
    },
  },
  mounted() {
    this.loadMessages();
    this.addPlaceholderMessage();
    window.addEventListener('beforeunload', this.clearMessages);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.clearMessages);
  },
  methods: {
    loadMessages() {
      const storedMessages = localStorage.getItem('lawphoriaMessages');
      if (storedMessages) {
        this.messages = JSON.parse(storedMessages);
      }
    },
    saveMessages() {
      if (this.messages.length > 50) {
        this.messages = this.messages.slice(-50);
      }
      localStorage.setItem('lawphoriaMessages', JSON.stringify(this.messages));
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    scrollToBottom() {
      const chatContainer = this.$refs.chatContainer;
      chatContainer.scrollTop = chatContainer.scrollHeight;
    },
    clearMessages() {
      localStorage.removeItem('lawphoriaMessages');
    },
    addPlaceholderMessage() {
      if (this.messages.length === 0) {
        this.messages.push({
          id: 'placeholder',
          type: 'ai',
          text: 'Welcome to Lawphoria. How can I assist you with Indian law today?'
        });
      }
    },
    async sendMessage() {
      const input = this.userInput.trim();
      if (input.length === 0 || this.isLoading) return;

      const messageId = Date.now();
      this.messages.push({ id: messageId, type: 'user', text: input });
      this.saveMessages();
      this.userInput = '';
      this.isLoading = true;

      const systemMessage = {
        role: "system",
        content: "You are Lawphoria, an AI assistant specialized in Indian law. Provide concise, accurate information about Indian legal matters. If a query is unrelated to Indian law, politely redirect the conversation."
      };

      const recentMessages = this.messages.slice(-10).map(msg => ({
        role: msg.type === 'user' ? 'user' : 'assistant',
        content: msg.text
      }));

      try {
        const response = await this.getAIResponse([systemMessage, ...recentMessages]);
        if (response) {
          this.messages.push({
            id: Date.now(),
            type: 'ai',
            text: response.trim()
          });
          this.saveMessages();
        }
      } catch (error) {
        console.error('Error getting AI response:', error);
        this.messages.push({
          id: Date.now(),
          type: 'ai',
          text: 'I apologize, but I encountered an error while processing your request. Please try again.'
        });
      } finally {
        this.isLoading = false;
      }
    },
    async getAIResponse(messages) {
      try {
        const response = await axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
              model: "gpt-4o-mini",
              messages: messages,
              temperature: 0.7,
              max_tokens: 2560,
              top_p: 1,
              frequency_penalty: 0,
              presence_penalty: 0,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.openaiApiKey}`,
              },
            }
        );

        return response.data.choices[0].message.content.trim();
      } catch (error) {
        console.error('Error getting response from OpenAI:', error);
        throw error;
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file.type.startsWith('image/')) {
        alert('Only image files are supported.');
        return;
      }
      this.convertToBase64(file);
      this.showUploadFinishedPopup = true;
    },
    convertToBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.uploadedImageBase64 = reader.result;
      };
      reader.onerror = error => {
        console.error('Error converting file to base64:', error);
      };
    },
    closeUploadPopup() {
      this.showUploadFinishedPopup = false;
      this.resetFileInput();
    },
    resetFileInput() {
      this.uploadedImageBase64 = null;
      this.$refs.fileInput.value = '';
    },
    async sendImageToLawphoria() {
      if (this.uploadedImageBase64) {
        this.isLoading = true;
        this.showUploadFinishedPopup = false;
        try {
          const response = await this.processDocument(this.uploadedImageBase64);
          this.messages.push({
            id: Date.now(),
            type: 'ai',
            text: response
          });
          this.saveMessages();
        } catch (error) {
          console.error('Error processing image:', error);
          this.messages.push({
            id: Date.now(),
            type: 'ai',
            text: 'I apologize, but I encountered an error while processing your image. Please try uploading it again.'
          });
        } finally {
          this.isLoading = false;
        }
      }
      this.resetFileInput();
    },
    async processDocument(base64Image) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.openaiApiKey}`
      };

      const payload = {
        model: "gpt-4o-mini",
        messages: [
          {
            role: "user",
            content: [
              {
                type: "text",
                text: "Analyze this image related to Indian law or government documents. If relevant, provide a concise explanation in both the original language and English. If not relevant, respond with 'This image does not appear to be related to Indian law or government documents.'"
              },
              {
                type: "image_url",
                image_url: {
                  url: base64Image
                }
              }
            ]
          }
        ],
        max_tokens: 3000
      };

      const response = await axios.post('https://api.openai.com/v1/chat/completions', payload, { headers });
      if (response.data.choices && response.data.choices.length > 0) {
        return response.data.choices[0].message.content.trim();
      } else {
        throw new Error('Unexpected response format from OpenAI API');
      }
    },
    toggleMode() {
      this.isLightMode = !this.isLightMode;
    },
    goToLawyerConnect() {
      this.$router.push('/lawyer-connect');
    },
    speakText(text) {
      const utterance = new SpeechSynthesisUtterance(text);
      speechSynthesis.speak(utterance);
    },
  }
};
</script>

<style scoped>
.lawphoria {
  --primary-color: #000000;
  --secondary-color: #D4AF37;
  --text-color: #FFFFFF;
  --bg-color: #111111;
  --chat-bg: #1A1A1A;
  --user-msg-bg: #333333;
  --ai-msg-bg: #222222;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Helvetica Neue', Arial, sans-serif;
  transition: all 0.3s ease;
}

.light-mode {
  --primary-color: #FFFFFF;
  --secondary-color: #D4AF37;
  --text-color: #000000;
  --bg-color: #F5F5F5;
  --chat-bg: #FFFFFF;
  --user-msg-bg: #E6F2FF;
  --ai-msg-bg: #F0F0F0;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: var(--primary-color);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.logo img {
  height: 60px;
}

.mode-toggle {
  position: absolute;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--secondary-color);
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  font-size: 2.5rem;
  background: linear-gradient(45deg, #D4AF37, #FFF8DC);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2rem;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.chatbot {
  width: 100%;
  max-width: 800px;
  height: 70vh;
  background-color: var(--chat-bg);
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.chat-container {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

.message {
  margin-bottom: 1rem;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.message-content {
  max-width: 80%;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.message-content:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.message.user .message-content {
  background-color: var(--user-msg-bg);
  color: var(--text-color);
  margin-left: auto;
}

.message.ai .message-content {
  background-color: var(--ai-msg-bg);
  color: var(--text-color);
}

.message-content h3 {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: var(--secondary-color);
}

.message-content p {
  font-size: 1rem;
  line-height: 1.5;
}

.input-container {
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: var(--primary-color);
  border-top: 2px solid var(--secondary-color);
}

.upload-btn, .send-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: linear-gradient(45deg, #D4AF37, #FFF8DC);
  color: var(--primary-color);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-btn {
  margin-right: 0.5rem;
}

.send-btn {
  margin-left: 0.5rem;
}

.upload-btn:hover, .send-btn:hover {
  transform: scale(1.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

input[type="text"] {
  flex: 1;
  padding: 0.8rem 1rem;
  border: none;
  border-radius: 20px;
  background-color: var(--chat-bg);
  color: var(--text-color);
  font-size: 1rem;
  transition: all 0.3s ease;
}

input[type="text"]:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--secondary-color);
}

.send-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

footer {
  padding: 1rem;
  background-color: var(--primary-color);
  color: var(--text-color);
  text-align: center;
  font-size: 0.9rem;
}

.upload-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--chat-bg);
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;
}

.upload-popup h3 {
  color: var(--secondary-color);
  margin-bottom: 1rem;
}

.popup-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.btn-primary, .btn-secondary {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-primary {
  background: linear-gradient(45deg, #D4AF37, #FFF8DC);
  color: var(--primary-color);
}

.btn-secondary {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.btn-primary:hover, .btn-secondary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 20px;
}

.loader div {
  position: absolute;
  top: 8px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: linear-gradient(45deg, #D4AF37, #FFF8DC);
  animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.loader div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.loader div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.loader div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

@keyframes loader {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

/* Improved Responsive design */
@media (max-width: 768px) {
  .logo img {
    height: 40px;
  }

  .title {
    font-size: 1.8rem;
  }

  .chatbot {
    height: calc(100vh - 200px);
  }

  .input-container {
    padding: 0.5rem;
  }

  input[type="text"] {
    font-size: 0.9rem;
    padding: 0.6rem 0.8rem;
  }

  .upload-btn, .send-btn {
    width: 36px;
    height: 36px;
  }

  .message-content {
    max-width: 90%;
  }
}

/* Transitions */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Scrollbar styling */
.chat-container::-webkit-scrollbar {
  width: 8px;
}

.chat-container::-webkit-scrollbar-track {
  background: var(--chat-bg);
}

.chat-container::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #D4AF37, #FFF8DC);
  border-radius: 20px;
  border: 3px solid var(--chat-bg);
}
</style>