<template>
  <div class="lawyer-connect">
    <div class="logo">
      <img src="@/assets/lawphoriaConnect.png" alt="Lawphoria Connect Logo">
    </div>
    <div class="search-box">
      <input type="text" v-model="searchQuery" placeholder="Enter your legal query or location" @keyup.enter="searchLawyers">
      <button @click="searchLawyers">Search</button>
    </div>
    <div class="lawyer-list">
      <div class="lawyer-card" v-for="lawyer in filteredLawyers" :key="lawyer.id">
        <div class="lawyer-icon">👤</div>
        <h3>{{ lawyer.name }}</h3>
        <p>{{ lawyer.specialization }}</p>
        <p>{{ lawyer.location }}</p>
        <button @click="connectWithLawyer(lawyer)">Connect</button>
      </div>
    </div>
    <div v-if="showEmailPopup" class="email-popup">
      <div class="popup-content">
        <h3>Lawyer's Email</h3>
        <p>{{ selectedLawyer.email }}</p>
        <button @click="closeEmailPopup">Close</button>
      </div>
    </div>
    <footer>
      <p>© 2024 Neuralway Technologies Private Limited. All rights reserved.</p>
      <p>Crafted by team ThinkDifferent - Advaik Sunil, P B Swaroop, Peeyush Gaur, Rahul Malekop</p>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: '',
      showEmailPopup: false,
      selectedLawyer: {},
      lawyers: [
        {
          id: 1,
          name: 'Rajesh Patel',
          specialization: 'Corporate Law',
          location: 'Mumbai',
          email: 'rajesh.patel@example.com',
        },
        {
          id: 2,
          name: 'Priya Sharma',
          specialization: 'Criminal Law',
          location: 'Delhi',
          email: 'priya.sharma@example.com',
        },
        {
          id: 3,
          name: 'Kiran Gowda',
          specialization: 'Intellectual Property Law',
          location: 'Bengaluru',
          email: 'kiran.gowda@example.com',
        },
        {
          id: 4,
          name: 'Neha Verma',
          specialization: 'Family Law',
          location: 'Hyderabad',
          email: 'neha.verma@example.com',
        },
        {
          id: 5,
          name: 'Shashank Menon',
          specialization: 'Tax Law',
          location: 'Kochi',
          email: 'shashank.menon@example.com',
        },
        {
          id: 6,
          name: 'Kavita Desai',
          specialization: 'Employment Law',
          location: 'Pune',
          email: 'kavita.desai@example.com',
        },
        {
          id: 7,
          name: 'Vikram Singh',
          specialization: 'Real Estate Law',
          location: 'Kolkata',
          email: 'vikram.singh@example.com',
        },
        {
          id: 8,
          name: 'Aisha Khan',
          specialization: 'Immigration Law',
          location: 'Hyderabad',
          email: 'aisha.khan@example.com',
        },
        {
          id: 9,
          name: 'Rohit Mehta',
          specialization: 'Environmental Law',
          location: 'Jaipur',
          email: 'rohit.mehta@example.com',
        },
        {
          id: 10,
          name: 'Shreya Mukherjee',
          specialization: 'Banking and Finance Law',
          location: 'Lucknow',
          email: 'shreya.mukherjee@example.com',
        },
        {
          id: 11,
          name: 'Arjun Rao',
          specialization: 'Intellectual Property Law',
          location: 'Surat',
          email: 'arjun.rao@example.com',
        },
        {
          id: 12,
          name: 'Divya Choudhary',
          specialization: 'Labor Law',
          location: 'Kanpur',
          email: 'divya.choudhary@example.com',
        },
        {
          id: 13,
          name: 'Rahul Malhotra',
          specialization: 'Mergers and Acquisitions',
          location: 'Nagpur',
          email: 'rahul.malhotra@example.com',
        },
        {
          id: 14,
          name: 'Simran Gupta',
          specialization: 'Intellectual Property Law',
          location: 'Indore',
          email: 'simran.gupta@example.com',
        },
      ],
    };
  },
  computed: {
    filteredLawyers() {
      return this.lawyers.filter(lawyer =>
          lawyer.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          lawyer.specialization.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          lawyer.location.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
    searchLawyers() {
      // The search functionality is handled by the computed property 'filteredLawyers'
    },
    connectWithLawyer(lawyer) {
      this.selectedLawyer = lawyer;
      this.showEmailPopup = true;
    },
    closeEmailPopup() {
      this.showEmailPopup = false;
    },
  },
};
</script>

<style scoped>
.lawyer-connect {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  min-height: calc(100vh - 80px);
  position: relative;
  padding-bottom: 100px; /* Add padding at the bottom */
}
.logo {
  text-align: center;
  margin-bottom: 20px;
}

.logo img {
  max-width: 200px;
  height: auto;
}

.search-box {
  display: flex;
  margin-bottom: 20px;
}

.search-box input {
  flex: 1;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-box button {
  padding: 12px 24px;
  font-size: 16px;
  background-color: #1c3d5a;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.lawyer-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 30px;
}

.lawyer-card {
  background-color: #f5f5f5;
  padding: 30px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.lawyer-icon {
  font-size: 48px;
  margin-bottom: 20px;
}

.lawyer-card h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.lawyer-card p {
  font-size: 16px;
  color: #666;
  margin-bottom: 15px;
}

.lawyer-card button {
  padding: 12px 24px;
  font-size: 16px;
  background-color: #1c3d5a;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.email-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
}

.popup-content h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.popup-content p {
  font-size: 18px;
  margin-bottom: 20px;
}

.popup-content button {
  padding: 12px 24px;
  font-size: 16px;
  background-color: #1c3d5a;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}



footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  color: #333;
  font-size: 14px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}
footer p {
  font-size: 14px;
}
</style>